import { Row, Col, colors, Container } from '@everlywell/leaves';
import SectionWrapper from 'components/SectionWrapper';
import { Themable, Theme } from 'components/Themable';
import React from 'react';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { CTA } from 'utils/types';

import treesImage from '../../../images/themes/Winter2020/trees.svg';
import pillImage from './pills.svg';
import * as S from './styles';

export interface BusinessPartnerSectionProps {
  title: string;
  headline: string;
  subHeadline: string;
  listOfLinks: CTA[];
}

export const BusinessPartnerSection: React.FC<BusinessPartnerSectionProps> = ({
  title,
  headline,
  subHeadline,
  listOfLinks,
}) => {
  const handleClick = (category: string) => {
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.HOMEPAGE_FOR_BUSINESS_CTA,
        category,
      },
    });
  };

  return (
    <>
      {/* TODO: remove this temporary white bg div and replace with future branding section */}
      <div style={{ backgroundColor: '#fff', width: '100%', height: '64px' }} />
      <SectionWrapper
        style={{ backgroundColor: colors.green1 }}
        data-testid="businessPartnerSection"
      >
        <S.Content>
          <Container>
            <Row>
              <Col xs={12} md={5}>
                <Themable>
                  <S.Img
                    // @ts-ignore - the theme prop is only used for Themeable to find and filter
                    theme={Theme.Default}
                    src={pillImage}
                    aria-hidden="true"
                    alt=""
                  />
                  <S.Img
                    // @ts-ignore - the theme prop is only used for Themeable to find and filter
                    theme={Theme.Winter2020}
                    src={treesImage}
                    aria-hidden="true"
                    alt=""
                  />
                </Themable>
                <S.Title text={title} />
                <S.Headline>{headline}</S.Headline>
              </Col>
              <Col xs={12} md={6} lg={5} lgOffset={1}>
                <S.Description>{subHeadline}</S.Description>
                <S.CTAContainer>
                  {listOfLinks.map((link, index) => (
                    <S.Button
                      appearance="secondary"
                      href={link.url}
                      hasArrow
                      key={index}
                      onClick={() => handleClick(link.text)}
                    >
                      {link.text}
                    </S.Button>
                  ))}
                </S.CTAContainer>
              </Col>
            </Row>
          </Container>
        </S.Content>
      </SectionWrapper>
    </>
  );
};

export default BusinessPartnerSection;
