import BusinessPartnerSection from 'components/Homepage/BusinessPartnerSection';
import React from 'react';
import { CTA } from 'utils/types';

export interface BusinessPartnerContainerProps {
  content: {
    title: string;
    headline: string;
    subHeadline: string;
    listOfLinks: CTA[];
  };
}

export const BusinessPartnerContainer: React.FC<
  BusinessPartnerContainerProps
> = ({ content }) => {
  if (!content) {
    return null;
  }
  const { title, headline, subHeadline, listOfLinks } = content;

  return (
    <BusinessPartnerSection
      title={title}
      headline={headline}
      subHeadline={subHeadline}
      listOfLinks={listOfLinks}
    />
  );
};

export default BusinessPartnerContainer;
