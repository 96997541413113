import {
  size,
  colors,
  typography,
  H2,
  mediaQueries,
  Button as RawButton,
} from '@everlywell/leaves';
import styled from 'styled-components';

import SectionTitle from '../../Enterprise/SectionTitle';

export const Section = styled.section`
  padding-top: ${size.xl4}px;
  margin-top: -${size.xl4}px;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  padding: 32px 0;

  ${mediaQueries.forTabletHorizontalUp} {
    min-height: 390px;
    padding-top: 32px;
  }
`;

export const Title = styled(SectionTitle)`
  margin-bottom: ${size.sm}px;
  text-align: center;
  color: ${colors.green5};
  ${mediaQueries.forTabletHorizontalUp} {
    display: none;
  }
`;

export const Headline = styled(H2)`
  margin-bottom: ${size.xl1}px;
  text-align: center;

  ${mediaQueries.forTabletHorizontalUp} {
    text-align: left;
  }
`;

export const Description = styled.p`
  ${typography.bodyText}
  text-align: left;
  margin-bottom: ${size.xl1}px;
  display: none;
  ${mediaQueries.forTabletHorizontalUp} {
    display: block;
  }
`;

export const Button = styled(RawButton)`
  margin-bottom: ${size.md}px;
  min-width: 212px;
  border-color: ${colors.green5};
  color: ${colors.green5};
  &:hover,
  &:focus {
    background-color: ${colors.green5};
  }
  &:last-child {
    margin-bottom: 0;
  }
  ${mediaQueries.forTabletHorizontalUp} {
    margin-right: ${size.md}px;
    margin-bottom: 0;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const CTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${mediaQueries.forTabletHorizontalUp} {
    flex-direction: row;
    justify-content: flex-start;
  }
`;

export const Img = styled.img`
  max-width: 60px;
  position: absolute;
  top: -36px;

  ${mediaQueries.forTabletHorizontalUp} {
    max-width: 99px;
    top: -59px;
  }
`;
